import React from "react"
import { Link } from "gatsby"
import Layout from "./../components/layouts/Default"

const notFound = ({ data, location }) => {
  return (
    <Layout
      title="404 - Seite nicht gefunden"
      description="Leider wurde die Seite nicht gefunden."
      location={location}
    >
      <div className="container mx-auto">
        <div className="row">
          <div className="py-5 text-center col-12">
            <h1>404</h1>
            <svg
              version="1.1"
              id="e2652d7b-d014-44e7-aeea-709bf92e6067"
              xmlns="http://www.w3.org/2000/svg"
              x="0"
              y="0"
              viewBox="0 -10 170 173"
              xmlSpace="preserve"
              className="inline py-4"
              style={{ maxWidth: "200px" }}
            >
              <path
                d="M141 92.5C119.5 125 109.4 163 70.5 163S0 131.4 0 92.5 35.2 38.4 70.5 22C185.2-31.2 189 19.8 141 92.5z"
                fill="#000000"
              />
              <g>
                <circle
                  style={{ fill: "#cbcbcb" }}
                  cx="132.1"
                  cy="9.7"
                  r="1.5"
                />
                <circle
                  style={{ fill: "#e6e6e6" }}
                  cx="133.4"
                  cy="18.8"
                  r="13.2"
                />
                <circle
                  style={{ fill: "#cbcbcb" }}
                  cx="134.7"
                  cy="24.8"
                  r="1.5"
                />
                <circle
                  style={{ fill: "#cbcbcb" }}
                  cx="140.5"
                  cy="16.4"
                  r="1.1"
                />
                <circle
                  style={{ fill: "#cbcbcb" }}
                  cx="128.2"
                  cy="19.9"
                  r="3.5"
                />
                <circle style={{ fill: "#1939ec" }} cx="88" cy="39.1" r="2.2" />
                <circle
                  style={{ fill: "#1939ec" }}
                  cx="145.9"
                  cy="44.7"
                  r="2.2"
                />
                <circle
                  style={{ fill: "#e6e6e6" }}
                  cx="120.2"
                  cy="48.8"
                  r="1.3"
                />
                <circle
                  style={{ fill: "#e6e6e6" }}
                  cx="20.3"
                  cy="89.7"
                  r="1.3"
                />
                <circle
                  style={{ fill: "#e6e6e6" }}
                  cx="97.1"
                  cy="124.5"
                  r="1.3"
                />
                <path
                  style={{ fill: "#1939ec" }}
                  d="M117.4 87.3l-.7.7-.6-.7-.4.5.6.6-.6.6.4.5.6-.7.7.7.4-.5-.6-.6.6-.6z"
                />
                <path
                  style={{ fill: "#e6e6e6" }}
                  d="M31.6 108.5l-.7.7-.6-.7-.5.4.7.7-.7.6.5.5.6-.7.7.7.4-.5-.7-.6.7-.7zM36.8 70.9l-.7.6-.6-.6-.5.4.7.7-.7.6.5.4.6-.6.6.6.5-.4-.7-.6.7-.7zM149.8 30.9l-.6.6-.7-.6-.4.4.6.7-.6.6.4.4.7-.6.6.7.4-.5-.6-.6.6-.7zM98.8 48.8l-.6.7-.7-.7-.4.5.6.6-.6.7.4.4.6-.7.7.7.4-.4-.6-.7.6-.6z"
                />
                <linearGradient
                  id="SVGID_1_"
                  gradientUnits="userSpaceOnUse"
                  x1="476.843"
                  y1="-31.588"
                  x2="563.653"
                  y2="-31.588"
                  gradientTransform="matrix(.1992 0 0 -.1992 7.905 64.56)"
                >
                  <stop offset="0" stopColor="#fff" />
                  <stop offset="1" stopColor="#fff" stopOpacity=".3" />
                </linearGradient>
                <path
                  d="M103.3 80.9l6.6-7.7 6.6-7.7 3.7-4.3c.2-.2-.1-.6-.3-.4l-6.6 7.7-6.6 7.7-3.7 4.3c-.2.3.1.6.3.4z"
                  opacity=".8"
                  fill="url(#SVGID_1_)"
                />
                <linearGradient
                  id="SVGID_2_"
                  gradientUnits="userSpaceOnUse"
                  x1="418.246"
                  y1="-170.484"
                  x2="505.056"
                  y2="-170.484"
                  gradientTransform="matrix(.1992 0 0 -.1992 7.905 64.56)"
                >
                  <stop offset="0" stopColor="#fff" />
                  <stop offset="1" stopColor="#fff" stopOpacity=".3" />
                </linearGradient>
                <path
                  d="M91.6 108.6l6.6-7.7 6.6-7.7 3.7-4.3c.2-.2-.1-.6-.3-.4l-6.6 7.7-6.6 7.7-3.7 4.3c-.2.2.1.6.3.4z"
                  opacity=".8"
                  fill="url(#SVGID_2_)"
                />
                <g>
                  <path
                    style={{ fill: "#2f2e41" }}
                    d="M58.5 66h12.3v4.8c0 1.7-1.4 3.1-3.1 3.1h-6c-1.7 0-3.1-1.4-3.1-3.1-.1.1-.1-4.8-.1-4.8z"
                  />
                  <path
                    style={{ fill: "#e6e6e6" }}
                    d="M74.9 130.4c-2.8 2.8-5.6 2.9-8.4.1l-2.2-19.1-1.5 19.2c-2.8 2.3-5.4 2.6-7.8.1-2.2-16.6-3.5-31.9-1.5-43.7l-4.1-16.6 9.3-4.3c1.9 4.6-1.1 7 5.6 6.9 6.6-.1 6.1-2.6 5.9-6.9l8.6 3.3c1.5.6 2.3 2.2 1.8 3.8l-4.3 13.7c1.6 6.8-.1 26-1.4 43.5z"
                  />
                  <path
                    style={{ fill: "#2f2e41" }}
                    d="M61.9 147.1s-6.9.6-7.8.2c-.1 0-.1-.1-.2-.1-1.4-.7-1.4-2.7-.1-3.5l2.7-1.8-2.4-12.8 9.2.1-1.4 17.9zM67.1 147.2s6.9.6 7.8.2c.1 0 .1-.1.2-.1 1.4-.7 1.4-2.7.1-3.5l-2.7-1.8 3.4-12.8h-9.6l.8 18z"
                  />
                  <path
                    style={{ fill: "#e6e6e6" }}
                    d="M50.9 101.7l1.4 5.5c.3 1.4-.2 2.8-1.4 3.6-1.5 1-3.6.5-4.5-1-.3-.4-.4-.9-.5-1.4l-.3-2.7-1.1-26c0-4.4 2.7-8.4 6.7-10.1l2.1-.9-2.4 33zM85.3 79.4v.2l-.9 21-.2 4.1v.8l-.3 2.7c-.2 1.8-1.8 3.1-3.6 2.9-1.8-.2-3.1-1.8-2.9-3.6 0-.2 0-.3.1-.4l1.4-5.5v-.4l-.2-2.5-2.2-30.1 2.1.9c4 1.5 6.7 5.5 6.7 9.9z"
                  />
                  <path
                    style={{ fill: "#2f2e41" }}
                    d="M65.2 103.3c-7.9 0-12.7-2.7-12.8-2.8l.2-.4c.1.1 9.8 5.5 24.1.8l.1.4c-3.7 1.3-7.6 1.9-11.6 2z"
                  />
                  <path
                    style={{ fill: "#2f2e41" }}
                    d="M57.4 123.2c-1.7-3.9-1.7-20.8-1.7-21.5h.4c0 .2 0 17.5 1.7 21.3l-.4.2z"
                  />
                  <path
                    style={{ fill: "#2f2e41" }}
                    d="M58.3 123.4c-1.5 0-2.6-.3-3.2-.7-1.2-.7-1.5-1.6-1.5-1.7l.4-.1-.2.1.2-.1s.2.8 1.3 1.4c1.2.6 3.5 1.1 8.1-.2l.1.4c-2.2.6-3.9.9-5.2.9z"
                  />
                  <path
                    transform="rotate(-2.804 55.067 85.028)"
                    style={{ fill: "#2f2e41" }}
                    d="M54.9 68.1h.4V102h-.4z"
                  />
                  <path
                    style={{ fill: "#2f2e41" }}
                    d="M72.1 123.2l-.4-.2c1.7-3.8 1.7-21.2 1.7-21.3h.4c0 .7 0 17.6-1.7 21.5z"
                  />
                  <path
                    style={{ fill: "#2f2e41" }}
                    d="M71.2 123.4c-1.3 0-3-.2-5.2-.9l.1-.4c4.6 1.4 6.9.9 8.1.2 1.1-.6 1.3-1.4 1.3-1.4l.4.1s-.3 1-1.5 1.7c-.7.3-1.7.7-3.2.7z"
                  />
                  <path
                    transform="rotate(-87.202 74.429 85.028) scale(.99999)"
                    style={{ fill: "#2f2e41" }}
                    d="M57.5 84.8h33.9v.4H57.5z"
                  />
                  <path
                    style={{ fill: "#1939ec" }}
                    d="M70.8 73.2l-3.2 4.7h-7l-2.3-4.7h1l1.8 3.8h5.8l2.8-3.8z"
                  />
                  <path d="M78.6 75.5V75.8z" />
                  <path
                    style={{ fill: "#1939ec" }}
                    d="M85.7 79.4v.2l-4.1 1.2-2.5-5.1 2.6-4.8c2.5 2.1 4 5.2 4 8.5zM44.2 79.4v.2l4.1 1.2 2.5-5.1-2.6-4.8c-2.6 2.1-4 5.2-4 8.5z"
                  />
                  <circle
                    style={{ fill: "#e6e6e6" }}
                    cx="64.6"
                    cy="58.1"
                    r="11.5"
                  />
                  <circle
                    style={{ fill: "#2f2e41" }}
                    cx="64.6"
                    cy="59.6"
                    r="8.4"
                  />
                  <path
                    style={{ fill: "#1939ec" }}
                    d="M84.5 100.5l-.1 4.1-5.6-3-.1-.5-.2-2.6zM45.1 100.5l.2 4.1 5.8-3 .2-.5.2-2.6z"
                  />
                  <path
                    style={{ fill: "#2f2e41" }}
                    d="M67.7 78.1h-7.2l-4-8.1-.2-3h.5l.2 2.9 3.8 7.8h6.6l4.9-7.2 1.1-3.2.4.1-1.1 3.3z"
                  />
                </g>
              </g>
            </svg>
            <p>
              <strong>Seite nicht gefunden</strong>
            </p>
            <p>
              Bitte benutze das Hauptmenü oder gehe zur{" "}
              <Link to="/" className="text-primary hover:underline">
                Startseite
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default notFound
